<template>
  <div
    class="wrapper-swiper-container"
    :style="{ '--wrapperHeight': wrapperHeight }"
  >
    <slot name="extra-header"></slot>
    <van-swipe
      class="swiper-container"
      v-bind="swipeOptions"
      :initial-swipe="initialSwipe"
      @change="handleSwiperChange"
    >
      <van-swipe-item
        :class="[
          { 'swiper-action': initialSwipe === i },
          { 'swiper-action': false && i === 0 },
        ]"
        v-for="(swipe, i) in dataSource"
        :key="swipe"
      >
        <!-- :style="{ 'margin-left': initialSwipe !== 0 && i === 0 ? '50px' : '' }" -->
        <img :src="swipe" alt="" />
      </van-swipe-item>
    </van-swipe>
    <slot name="extra-footer"></slot>
  </div>
</template>

<script>
export default {
  name: "WrapperSwiper",
  props: {
    wrapperHeight: {
      type: String,
      default: "492px",
    },
    dataSource: {
      type: Array,
      default: [],
    },
    swipeOptions: {
      type: Object,
      default: () => ({
        width: 205,
        showIndicators: false,
        loop: false,
      }),
    },
  },
  data: () => ({
    initialSwipe: 1,
  }),
  methods: {
    handleSwiperChange(index) {
      this.initialSwipe = index;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.wrapper-swiper-container {
  width: 100%;
  height: var(--wrapperHeight);
  background-color: $bgColorfff;
  padding: 60px 0 100px 0;
  box-sizing: border-box;
  border-radius: 28px;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1),
    0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  .swiper-container {
    height: 640px;
    ::v-deep(.van-swipe-item) {
      padding: 0 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
    }
    ::v-deep(.van-swipe-item:first-of-type) {
      padding-left: 0 50px 0 300px;
    }
    ::v-deep(.van-swipe__track) {
      padding-left: 100px;
    }
    img {
      width: 330px;
      height: 552px;
      border-radius: 36px;
      transition: all 0.3s ease;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.11);
    }
    .swiper-action {
      width: 490px !important;
      img {
        width: 390px !important;
        height: 640px !important;
      }
    }
  }
}
</style>
