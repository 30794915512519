<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">新农村</div>
      <div class="sub-title">区域数字经济建设方案</div>
    </div>
    <img
      class="large-image-h364"
      src="@/image/region/img_banner_village@3x.png"
    />
    <div class="describe-container-box">
      <div class="describe">助力乡村振兴发展，是每个企业的社会责任与义务。</div>
      <div class="introduce">
        为推动区域经济及乡村振兴发展，全球蛙通过打造数字经济产业聚集，助力企业高质量发展，加快乡村振兴培育工作，促进农村电商产业转型升级，强化数字经济与三二一产实体经济的深入融合，推动区域经济发展，实现本地商品卖外地、本地税收留本地、本地人才留本地，最终助力本地经济的复苏与增长。
      </div>
    </div>
    <img
      class="large-image-h436"
      src="@/image/region/img_cultivate_village@3x.png"
      @click="() => $router.push('/region/data-fusion')"
    />
    <img
      class="large-image-h596"
      src="@/image/region/img_county_village@3x.png"
      @click="() => $router.push('/region/county-level')"
    />
    <div class="func-module-container">
      <div class="func-title">数商兴农·企业担当</div>
      <div class="func-sub-title">数智赋能乡村建设，助力乡村全面振兴</div>
      <div class="func-describe">
        全球蛙紧跟国家乡村振兴战略，于2021年9月正式开启“乡村振兴”项目，发挥自身科技零售产业互联网平台优势，以数智化为基础，打通农村产业多流通渠道，率先深入山西洪洞县、古交市和榆社县云竹镇打造出“乡村e镇”雏形，未来，全球蛙将继续深入挖掘区域经济建设核心诉求，培育县域电商生态、挖掘培育县域电商人才，拓展农产品销售渠道，发展富民优势产业，建功乡村振兴，助推区域经济高质量发展。
      </div>
    </div>
    <div class="region-map-image-container">
      <img
        class="large-image-h1074"
        src="@/image/region/img_map_village@3x.png"
      />
    </div>
    <div class="enable-container">
      <div class="enable-title">实施案例</div>
      <div class="enable-sub-title">深入山西开展乡村振兴，打造数字新农村</div>
      <wrapper-swiper
        v-for="swiper in wrapperSwiperList"
        :key="swiper.key"
        :dataSource="swiper.dataSource"
      >
        <template #extra-header>
          <div style="margin-bottom: 20px">
            <div class="swiper-title">{{ swiper.title }}</div>
            <div
              class="swiper-describe"
              v-for="des in swiper.describe"
              :key="des"
            >
              {{ des }}
            </div>
          </div>
        </template>
      </wrapper-swiper>
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
import { WrapperSwiper } from "./components";
/* 零售 */
const retail = [
  require("../../image/region/img_ht1_village@3x.png"),
  require("../../image/region/img_ht2_village@3x.png"),
  require("../../image/region/img_ht3_village@3x.png"),
];
/* 零售 */
const online = [
  require("../../image/region/img_gj1_village@3x.png"),
  require("../../image/region/img_gj2_village@3x.png"),
  require("../../image/region/img_gj3_village@3x.png"),
];
/* 零售 */
const benchmark = [
  require("../../image/region/img_ys1_village@3x.png"),
  require("../../image/region/img_ys2_village@3x.png"),
  require("../../image/region/img_ys3_village@3x.png"),
];
export default {
  mixins: [enumerableRoutesMixin],
  components: {
    WrapperSwiper,
  },
  data: () => ({
    wrapperSwiperList: [
      {
        key: 1,
        title: "全国首家县域数字零售平台 洪洞购",
        describe: [
          "2021年，全球蛙联合山西省妇联开展“乡村振兴 巾帼助农”",
          "项目落地洪洞县打造“三晋巾帼好品·洪洞购”",
        ],
        dataSource: retail,
      },
      {
        key: 2,
        title: "地方疫情防控期间指定线上平台 古交购",
        describe: [
          "2022年2月11日，由全球蛙全力研发的“古交购”县域数字",
          "平台，在市场供应突发事件实战演练中发挥重要作用",
        ],
        dataSource: online,
      },
      {
        key: 3,
        title: "县域数字零售平台示范标杆 榆社购",
        describe: [
          "2021年9月，全球蛙通过线上小程序与线下实体店结合，",
          "打造“榆社购”县域数字零售平台，加速构建数字乡村",
        ],
        dataSource: benchmark,
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.introduce {
  margin-bottom: 20px !important;
}
.large-image-h364 {
  margin: 58px 0;
}
.large-image-h596 {
  margin-bottom: 30px;
}
.introduce,
.func-describe {
  color: $textColor666 !important;
}
.func-title {
  color: $textColor333 !important;
  font-size: 40px !important;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold !important;
}
.func-sub-title {
  font-size: 28px !important;
  font-family: PingFang SC, PingFang SC-Regular !important;
}
.region-map-image-container {
  background-color: $bgColorf2f5fa;
}
.enable-container {
  padding-bottom: 0 !important;
}
.enable-title {
  font-size: 40px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold;
  color: $textColor222;
}
.enable-sub-title {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  margin-bottom: 60px;
}
.swiper-title {
  text-align: center;
  font-size: 28px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold;
  color: $textColor222;
  line-height: 40px;
  margin-bottom: 20px;
}
.swiper-describe {
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC, PingFang SC-Regular;
  color: $textColor999;
  line-height: 42px;
}
.contact-us {
  padding-top: 0 !important;
}
</style>
